// eslint-disable
// this is an auto generated file. This will be overwritten

export const createSiteContact = `mutation CreateSiteContact($input: CreateSiteContactInput!) {
  createSiteContact(input: $input) {
    id
    name
    email
    subject
    message
    target_email
  }
}
`
