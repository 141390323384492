import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SlideShow from "../components/slideshow"
import Contact from "../components/contact-form"

import { createSiteContact } from "../graphql/mutations"
import { gql, useMutation } from "@apollo/client"

const ContactPage = () => {
  const [addContact] = useMutation(gql(createSiteContact))

  const doSendMessage = async values => {
    await addContact(
      {
        variables: { input: values },
      })
  }

  return (
    <Layout>
      <SEO title="Contacto" />
      <SlideShow style={{ height: "60vh" }}>
        <h1>Contacto</h1>
        <Contact doSendMessage={doSendMessage} />
      </SlideShow>
    </Layout>
  )
}

export default ContactPage
