import React, { useRef } from "react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import styled from "@emotion/styled"
import themeColors from "./theme-colors"
import Color from "color"
import { ReCaptcha } from "react-recaptcha-google"
import { graphql, useStaticQuery } from "gatsby"

const FormControl = ({ label, fieldName, type = "text", ...otherProps }) => (
  <div>
    <label>
      {label}
      <Field type={type} name={fieldName} {...otherProps} />
    </label>
    <ErrorMessage name={fieldName} />
  </div>
)

const Container = styled.section`
  background: ${Color(themeColors.fontColor.value)
    .lighten(0.2)
    .toString()};
  padding: 1.6rem;
  width: 100%;
  border-radius: 0.4rem;

  font-size: 1em;

  div {
    display: flex;
    margin-bottom: 0.5em;
  }

  label {
    color: ${themeColors.backColor.value};
    font-weight: 500;
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  input,
  textarea {
    line-height: 1.45rem;
    font-size: 1em;
    padding: 0.2em;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  button {
    font-weight: 600;
    padding: 0.6em 0.8em;
    background: ${themeColors.backColor.inverse};
    color: ${themeColors.fontColor.inverse};
    font-size: 1em;
    border-radius: 0.4em;
    align-self: flex-end;
    border: 1px solid ${themeColors.backColor.inverse};
    cursor: pointer;

    :hover {
      background: ${themeColors.backColor.value};
      color: ${themeColors.fontColor.value};
    }
  }
`

const ContactForm = ({ doSendMessage }) => {
  const {
    site: {
      siteMetadata: { reCaptchaKey, targetEmail },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          reCaptchaKey
          targetEmail
        }
      }
    }
  `)

  const recaptchaRef = useRef(null)

  return (
    <Container>
      <Formik
        initialValues={{
          name: "",
          email: "",
          subject: "Pedido de reserva",
          message: "Desejo reservar ",
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          recaptchaRef.current.execute()

          doSendMessage({
            ...values,
            target_email: targetEmail,
          })

          setSubmitting(false)

          resetForm({
            name: "",
            email: "",
            subject: "",
            message: "",
          })
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormControl label="Nome" fieldName="name" autoFocus={true} />
            <FormControl label="Email" type="email" fieldName="email" />
            <FormControl label="Assunto" fieldName="subject" />
            <FormControl
              label="Mensagem"
              fieldName="message"
              component="textarea"
              rows="4"
            />

            <ReCaptcha
              ref={recaptchaRef}
              size="invisible"
              sitekey={reCaptchaKey}
              verifyCallback={console.log}
              onChange={console.log}
            />

            <button type="submit" disabled={isSubmitting}>
              Enviar mensagem
            </button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ContactForm
